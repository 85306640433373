/*-----------------------------------------------------------------
- General trans
-----------------------------------------------------------------*/
export default {
    
    sidebar: {
        developerTools: 'Developer Tools',
        preview       : 'Preview',
        components    : 'Components',
        settings      : 'Settings',
        lab           : 'Lab',
        info          : 'Model info',
        routes        : 'Routes',
        
        help: 'Help',
    },
    
    widget: {
        title       : 'Widget',
        added       : 'Widget added successfully',
        removed     : 'Widget deleted successfully',
        updated     : 'Widget updated successfully',
        sorted      : 'Widgets updated successfully',
        library     : 'Widgets Library',
        need_setting: 'You need to setting widget for using it',
    },
    
    widgets: {
        content: {
            empty: 'Empty',
        },
        
        user_finder: {
            form: {
                placeholder: 'Search User...',
                button     : 'Search',
            },
            
            result: {
                view_profile: 'View Profile',
                no_result   : 'User ',
                more_result : 'More Results',
            },
        },
        
        org_finder: {
            form: {
                placeholder: 'Search Organization...',
                button     : 'Search',
            },
            
            result: {
                view_profile: 'View Profile',
                no_result   : 'Organization ',
                more_result : 'More Results',
            },
        },
        
        order_finder: {
            form: {
                placeholder: 'Search Order by Tracking Number',
                button     : 'Search',
                reset      : 'Reset Search',
            },
            
            result: {
                view_order: 'View Order',
                
                info: {
                    tracking_number: 'Order Tracking Number',
                    order_status   : 'Order Status',
                    user_name      : 'Customer Name',
                    date           : 'Created Date',
                    payable_amount : 'Payable Amount',
                    paid_amount    : 'Paid Amount',
                    pay_status     : 'Pay Status',
                },
                
                no_result: 'Orders ',
            },
        },
        
        welcome: {
            greeting             : 'Welcome to Quarkino!',
            about_quark          : 'We are glad that you decided to choose Quarkino Solution.',
            about_quark_desc     : 'Welcome to Quarkino, the comprehensive platform designed to streamline your digital needs. Discover the full potential of Quarkino by watching our detailed video introduction. For real-time support and community interaction, join our Discord channel. To deepen your understanding, read through our extensive documentation, and to get hands-on experience, access our Postman collection today. Dive into Quarkino and start transforming your projects with us!', // eslint-disable-line
            homepage_link_support: 'Support',
            homepage_link_info   : 'More info',
            postman              : 'Postman Collection',
            document             : 'Document',
            discord              : 'Support on Discord',
        },
    },
    
};
