<template>
    <div
        v-if="display"
        class="modal-overlay"
        @click="doNothing"
    >
        <transition
            :name="transition"
            appear
        >
            <div
                class="modal"
                :class="classes"
                @click.stop
            >
                <button
                    v-if="!forced"
                    type="button"
                    class="close-modal"
                    @click="close"
                >
                    <i v-html="'&times;'" />
                </button>

                <section
                    class="panel"
                    :class="panelClasses"
                >
                    <header v-if="$slots['header'] || title">
                        <slot name="header">
                            {{ title }}
                        </slot>
                    </header>

                    <article v-if="$slots['body']">
                        <slot name="body" />
                    </article>

                    <slot />

                    <footer v-if="$slots['footer']">
                        <slot name="footer" />
                    </footer>
                </section>
            </div>
        </transition>
    </div>
</template>

<script>

    export default {
        name: 'YModal',

        inject: {
            $validator: '$validator',
        },
        props: {

            /**
             * Modal title
             */
            title: String,

            /**
             * Modal panel size
             */
            size: {
                type   : String,
                default: 'md',
            },

            /**
             * Default modal display
             */
            show: {
                type   : Boolean,
                default: false,
            },

            /**
             * Disables modal actions
             */
            fullscreen: {
                type   : Boolean,
                default: false,
            },

            /**
             * Transition to show modal
             */
            transition: {
                type   : String,
                default: 'slideDown',
            },

            /**
             * Prevents closing modal
             */
            forced: {
                type   : Boolean,
                default: false,
            },

            /**
             * Modal type
             */
            type: {
                type: String,
            },
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                /**
                 * Controls modal display
                 */
                display: this.show,
            };
        },

        computed: {

            /**
             * Return type of panel
             *
             * @returns {Array}
             */
            typeColor() {
                const types = {
                    default: '',
                    danger : 'red',
                    warning: 'orange',
                    success: 'green',
                    info   : 'blue',
                };
                return types[this.type || 'default'];
            },

            /**
             * Return size classes
             */
            getSizeClass() {
                return this.size;
            },

            /**
             * Return fullscreen class
             */
            getFullscreenClass() {
                return this.fullscreen ? 'fullscreen' : '';
            },

            /**
             * Merge all classes
             */
            classes() {
                const classes = [];

                classes.push(this.getSizeClass);
                classes.push(this.getFullscreenClass);
                classes.push(this.typeColor);

                return classes.join(' ');
            },

            /**
             * Panel classes
             */
            panelClasses() {
                const classes = [];

                classes.push(this.typeColor);

                return classes.join(' ');
            },
        },

        /**
         * @inheritDoc
         */
        mounted() {
            // Set event listener for Esc
            this.setListenerForEsc();
        },

        methods: {

            /**
             * Open modal and emit open modal
             */
            open() {
                this.display = true;
                this.$emit('open');
            },

            /**
             * Close modal and emit close event
             */
            close() {
                this.display = false;
                this.$emit('close');
            },

            /**
             * Toggle modal view
             */
            toggle() {
                if (this.display) {
                    this.close();
                } else {
                    this.open();
                }
            },

            /**
             * Set Event listener on document to close modal on Esc pressed
             */
            setListenerForEsc() {
                const vm = this;

                document.addEventListener('keyup', (e) => {
                    if (e.code === 'Escape' && !this.forced) {
                        vm.close();
                    }
                });
            },

            /**
             * Does Absolutely Nothing
             *
             * @returns {null}
             */
            doNothing() {
                return null;
            },

        },
    };
</script>
