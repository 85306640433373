<template>
    <div class="field slug-wrapper">
        <label
            v-if="!element.no_label"
            :for="labelFor"
        >{{ $t('fields.slug') }}</label>

        <div class="slug-field">
            <div class="slug-content">
                <span
                    v-if="rootUrl"
                    class="root"
                    v-html="rootUrl"
                />
                <span class="end">
                    <y-form-input
                        v-model="model"
                        type="text"
                        :name="element.name"
                        :placeholder="element.placeholder"
                        :disabled="element.disabled"
                        :readonly="element.readonly"
                        @blur="checkAvailability"
                    />
                </span>
                <span
                    v-if="shouldShowStatus"
                    class="status-wrapper"
                >
                    <div class="slug-status">
                        <y-loading
                            v-if="isValidatingSlug"
                            :active="true"
                            height="25px"
                        />
                        <i
                            v-else-if="isAvailable"
                            class="icon icon-valid md-check-circle"
                        />
                        <i
                            v-else-if="!isAvailable"
                            class="icon icon-invalid md-close-circle"
                        />
                    </div>
                </span>
            </div>
            <div
                v-if="!isValidatingSlug && suggestion"
                class="suggested-slug"
            >
                {{ `${$t('components.slug.suggested_slug')}: ${suggestion}` }}
                <y-copy-value :value="suggestion" />
            </div>
        </div>
    </div>
</template>

<script>

    import Vue from 'vue';
    import FormElementMixin from '@/mixins/FormElement';
    import { YFormInput } from '@deps/form/elements/Input';
    import { slug } from '@nodes/helpers/string';
    import YCopyValue from '@deps/CopyValue';

    export default {

        name: 'YFormPostSlug',

        components: {
            YFormInput,
            YCopyValue,
        },

        mixins: [FormElementMixin],

        props: {
            /**
             * Element of slug
             */
            element: Object,

            /**
             * Input Value
             */
            value: String,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model          : this.value,
                isAvailable    : false,
                suggestion     : '',
                prevCheckedSlug: null,
            };
        },

        /**
         * @inheritdoc
         */
        computed: {

            /**
             * Return label target name
             *
             * @returns {*|null}
             */
            labelFor() {
                return this.element.name || null;
            },

            /**
             * Check if is checking availability of the slug
             *
             * @returns {boolean}
             */
            isValidatingSlug() {
                return this.$wait.is('validating-slug');
            },

            /**
             * Return if the slug status should be shown
             *
             * @returns {boolean}
             */
            shouldShowStatus() {
                return this.isValidatingSlug || this.prevCheckedSlug !== null;
            },

            /**
             * Return root url to show as prefix
             */
            rootUrl() {
                let root = this.get(this.element, 'url', '');
                const pattern = this.get(this.element, 'url_pattern', '');
                if (root.charAt(root.length - 1) !== '/') {
                    root += '/';
                }
                root += `${pattern}`;
                return root;
            },

        },

        watch: {
            /**
             * Watch model and emit
             *
             * @param newVal
             * @param oldVal
             */
            model(newVal, oldVal) {
                if (newVal !== oldVal) {
                    const sanitizedNewSlug = slug(newVal);
                    const sanitizedOldSlug = oldVal ? slug(oldVal) : '';

                    if (sanitizedNewSlug !== newVal) {
                        // To do: replace nextTick with a better solution
                        Vue.nextTick(() => {
                            this.model = sanitizedNewSlug;
                        });
                    }

                    if (sanitizedNewSlug !== sanitizedOldSlug) {
                        this.$emit('input', sanitizedNewSlug);
                    }
                }
            },
        },

        methods: {

            /**
             * Emit input event on update text
             *
             * @param event
             */
            updateText(event) {
                this.$emit('input', event.target.value);
            },

            /**
             * Check if the slug is available
             */
            checkAvailability() {
                if (this.model === '' || this.model === this.prevCheckedSlug) {
                    return;
                }

                const params = {
                    type  : this.element.type,
                    locale: this.element.locale,
                    slug  : this.model,
                };

                if (this.$route.params.id) {
                    params.id = this.$route.params.id;
                }

                this.$wait.start('validating-slug');

                this.$services.Content.slugSuggest(params).then((response) => {
                    this.isAvailable = response.data.results.is_available;
                    this.suggestion = response.data.results.suggestion || '';
                    this.prevCheckedSlug = this.model;
                }).catch((errorResponse) => {
                    this.handleError(errorResponse);
                }).finally(() => {
                    this.$wait.end('validating-slug');
                });
            },

        },

    };
</script>
